.ColorClock {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  -webkit-text-shadow: -0.1px -0.1px 1px #333, 0.1px -0.1px 1px #333,
    -0.1px 0.1px 1px #333, 0.1px 0.1px 1px #333;
  -moz-text-shadow: -0.1px -0.1px 1px #333, 0.1px -0.1px 1px #333,
    -0.1px 0.1px 1px #333, 0.1px 0.1px 1px #333;
  text-shadow: -0.1px -0.1px 1px #333, 0.1px -0.1px 1px #333,
    -0.1px 0.1px 1px #333, 0.1px 0.1px 1px #333;
  text-align: center;
}

.ColorClock h1 {
  font-size: 10rem;
}

.ColorClock h4 {
  margin-bottom: 1rem;
}

.ColorClock--active {
  justify-content: space-between;
}

.ColorClock--active::before {
  content: "";
  height: 12.4rem;
  visibility: hidden;
}

.ColorClock--icon-wrapper {
  display: flex;
  justify-content: center;
}

.ColorClock--early {
  color: #333;
  text-shadow: none;
}

.ColorClock--early p {
  font-weight: bold;
}