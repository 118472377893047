.App {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes slidein_about {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slidein_form_from_clock {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slidein_form_from_about {
  0% {
    opacity: 0;
    transform: translateY(0%);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideout_form_to_about {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes slideout_about {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: translateY(-100%);
  }
}


@keyframes slideout_form_to_clock {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  80% {
    opacity: 0;
  }

  100% {
    transform: translateY(-200%);
  }
}


@keyframes slidein_clock {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  80% {
    opacity: 1;
  }

  100% {
    transform: translateY(0%);
  }
}

.form-exit-active {
  animation: slideout_form_to_about 1000ms forwards;
}

.form-enter-active {
  animation: slidein_form_from_about 1000ms forwards;
}

.about-enter-active {
  animation: slidein_about 1000ms forwards;
}

.about-exit-active  {
  animation: slideout_about 1000ms forwards;
}

.clock-exit-active + .form-enter-active {
  animation: slidein_form_from_clock 1000ms forwards;
}

.clock-enter-active + .form-exit-active {
  animation: slideout_form_to_clock 1000ms forwards;
}

.clock-enter-active { 
  animation: slidein_clock 1000ms forwards;
}
