.ClockFormRow.error .time {
  color: red;
  position: relative;
}

.ClockFormRow.error .time input {
  border: 1px solid red;
  border-radius: 2px;
  color: red;
}

.ClockFormRow.error .time span {
  color: red;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: bold;
  position: static;
  display: block;
  text-align: right;
  margin-top: -1.5rem;
}

.ClockFormRow--heading {
  text-align: center;
  margin: 1.5rem 0 0.25rem;
}

@media (min-width: 60rem) {
  .ClockFormRow.error .time span {
    position: absolute;
    bottom: -5px;
    right: 10px;
  }
}
