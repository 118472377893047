body {
  margin: 0;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
}
