.ClockForm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}

.ClockForm--form {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.ClockForm--form input[type="time"] {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.1rem solid #d1d1d1;
  border-radius: 0.4rem;
  box-shadow: none;
  box-sizing: inherit;
  height: 3.8rem;
  padding: 0.6rem 1rem 0.7rem;
  width: 100%;
}

.ClockForm--button.button {
  background-color: #2196f3;
  border-color: #2196f3;
  width: 100%;
  height: 5rem;
  margin-top: 1.5rem;
}

.ClockForm--form input:focus {
  border-color: #2196f3;
}

.ClockForm--button.button.hidden {
  pointer-events: none;
  opacity: 0;
}

@media (min-width: 80rem) {
  .ClockForm--form {
    width: 50%;
  }

  .ClockForm {
    align-items: center;
  }
}