.ProgressBar {
  width: 90%;
  padding: 0 2rem;
  background-color: white;
  border: 0.1rem solid #d1d1d1;
  border-radius: 0.2rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem;
  opacity: 1;
  transition: opacity 1s;
}

.ProgressBar.hidden {
  opacity: 0;
}

.ProgressBar--bar {
  height: 3rem;
  margin: 0 2rem;
  border-radius: .2rem;
  border: 0.1rem solid #333;
  position: relative;
}

.ProgressBar--caret {
  color: #333;
  position: absolute;
  bottom: -2rem;
  margin-left: -1rem;
  transition: left 1s linear;
}
